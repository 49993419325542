{
	"general" : {
		"Add": "Add",
		"Cancel": "Cancel",
		"Edit": "Edit",
		"Share": "Share",
		"View": "View",
		"Loading": "Loading...",

		"APP_Language": "APP Language",
		"International_sale_contract": "International sales contract",
		"INTERNATION_SALE_CONTRACT_DESCRIPTION": "ICC International Sales Contract (Manufactured Products for Resale)",
		"PRODUCT_EXPORT_ALERT_MSG": "WARNING! Special authorizations are required to export the indicated products to the destination country. Make sure you have all authorizations before concluding the contract.",
		"Save": "Save",
		
		"Business_name": "Business name",
		"Vat_code": "VAT number",
		"Street_or_Square": "Street or Square",
		"Country": "State",
		"City": "City",
		"Provinces": "Province",
		"Postal_code": "ZIP",
		
		"Data": "Data",
		"Seller": "Seller",
		"Buyer": "Buyer",
		"Legal_representative": "Legal representative",
		"Contact_person": "Contact person",
		"Name": "Name",
		"Surname": "Surname",
		"Email": "Email",
		"Phone": "Phone",
		"Signatory": "Signatory",
		"SIGNATORY_TIP": "Specifies whether the legal representative is also a signatory of the contract.",
		"Personal_responsibility": "Personal responsibilities",
		"PERSONAL_RESPONSIBILITY_TIP": "Specifies whether the legal representative is personally liable for this contract. Otherwise, the responsibility is assumed to fall on the company.",
		
		
		"Login_to_your_account": "Login to your account",
		"Manage_your_contracts": "Manage your contracts",
		"Password": "Password",
		"PLACEHOLDER_Email": "Your email",
		"PLACEHOLDER_Password": "Your password",

		"Terms": "General conditions",
		"TERMS_DESCRIPTION": "All the general conditions must be specified here.",
		
		"Contract_saved_title": "Contract saved!",
		"Contract_saved_subtitle": "The transaction has been stored on Blockchain",
			
		"VALIDATION_REQUIRED_FIELD": "Required field.",
		"VALIDATION_PHONE_FIELD": "Required field. Must contain 8 to 12 digits."
	},
	"intro": {
		"WELCOME": "Welcome to filling out the international sales contract!",
		"SELLER": "The seller's details will be entered here.",
		"BUYER": "Buyer's here!",
		"GENERAL_TERMS": "In this block you will find the general conditions of the contract.",
		"SPECIAL_TERMS": "In all subsequent blocks you will find the special conditions of the contract."
	},
	"A1": {
		"TITLE": "A-1 Products sold",
		"Code": "Code",
		"Product_code": "Product code",
		"Description": "Description",
		"Product_description": "Product description",
		"Quantity": "Quantity",
		"Price": "Price",
		"Add_product": "Add product"
	},
		"A2": {
		"TITLE": "A-2 Contract Price",
		"Amount": "Amount",
		"Total_amount": "Total amount"
	},
	"A3": {
		"TITLE": "A-3 Shipping Terms",
		"ALERT_MSG": "Recommended terms (according to Incoterms 1990): see Introduction, § 5)",
		"Terms_choose": "Terms choice",
		"Shipping_carrier": "Shipping carrier",
		"Contact_person": "Contact person",
		"Catalog": "Catalog",
		"Select": "Select...",
		"Term": "Term",
		"Notes": "Notes",
		"EXW": "EXW - Ex Works",
		"FCA": "FCA - Free Carrier",
		"FAS": "FAS - Free Alongside",
		"FOB": "FOB - Free on Board",
		"CFR": "CFR - Cost and Nest",
		"CPT": "CPT - Cost and Freight",
		"CIF": "CIF - Cost, insurance and freight",
		"CIP": "CIP - Freight and insurance paid up to",
		"CPT": "CPT - Freight paid until",
		"DAF": "DAF - Delivered at border",
		"DAP": "DAP - Delivered at the named place of destination",
		"DAT": "DAT - Return to Terminal",
		"DDP": "DDP - Delivered customs paid",
		"DDU": "DDU - Returned without customs duty",
		"DEQ": "DEQ - Dock Return",
		"DES": "DES - Returned ex ship",
		"DPU": "DPU - Delivered to unloaded location"
	},
	"A4": {
		"TITLE": "A-4 Delivery deadline",
		"ALERT_MSG": "Indicate here the date or period (e.g. week or month) on or by which the Seller is required to fulfill its delivery obligation in accordance with clause A4 of the respective Incoterm: see Introduction, § 6 )",
		"Shipping_date_min": "Delivery date (min)",
		"Shipping_date_max": "Delivery date (max)",
		"DATE_PLACEHOLDER": "dd/mm/YYYY"
	},
	"A5": {
		"TITLE": "A-5 Product Inspection (Art. 3)",
		"Inspection_type": "Inspection type",
		"Select": "Select...",
		"Before_shipping": "Before shipping",
		"Other": "Other",
		"Notes": "Notes"
	},
	"A6": {
		"TITLE": "A-6 Retention of title (Art. 7)",
		"Retention_of_title": "Retention of title",
		"None": "None",
		"Simple": "Simple",
		"Extended": "Extended",
		"TIP_MSG": "Select the retention of title for transferring ownership of the products from the seller to the buyer."
	},
	"A7": {
		"TITLE": "A-7 Payment conditions (Art. 5)",
		"Delayed_payment": "Delayed payment (art. 5.1)",
		"Prepayment": "Advance payment (art. 5.2)",
		"Payment_against_documents": "Payment against documents (art. 5.5)",
		"Irrevocable_documentary_credit": "Irrevocable documentary credit (art. 5.3)",
		"Other": "Other"
	},
	"A8": {
		"TITLE": "A-8 Documents",
		"ALERT_MSG": "Indicate here the documents that the Seller must procure. The parties are advised to check the Incoterm they have chosen in box A-3 of these Special Conditions. As regards transport documents, see also Introduction, § 8.",
		"Invoice": "Commercial invoice",
		"DDT": "Transport document",
		"Insurance_document": "Insurance document",
		"Origin_certificate": "Certificate of origin",
		"Inspection_certificate": "Inspection certificate",
		"Packing_list": "Packing_list",
		"Other": "Other"
	},
	"A9": {
		"TITLE": "A-9 Termination Date",
		"ALERT_MSG": "TO BE COMPLETED ONLY IF THE PARTIES WISH TO MODIFY ARTICLE 10.3. If, for any reason (including force majeure), the products have not been delivered by the termination date the Buyer will have the right to IMMEDIATELY TERMINATE THE CONTRACT BY SIMPLE WRITTEN COMMUNICATION TO THE SELLER.",
		"Termination_date": "Termination date",
		"DATE_PLACEHOLDER": "dd/mm/YYYY"
	},
	"A10": {
		"TITLE": "A-10 Liability for late delivery (Art. 10.1, 10.4, 11.3)",
		"ALERT_MSG": "TO BE COMPLETED ONLY IF THE PARTIES WISH TO MODIFY ARTICLES 10.1, 10.4 or 11.3.",
		"LATE_SHIPPING_PENALTY_CTA": "The penalty for late delivery will be the percentage specified below (of the price of the products delivered late) for each week of delay, within the maximum percentage limit specified below (of the price of the products above). ",
		"EXCEED_AMOUNT_CTA": "In the event of termination for late delivery, Seller's liability for damages due to late delivery is limited to the percentage specified below of the price of the undelivered products.",
		"SELLER_RESPONSABILITY_CTA": "The Seller's liability for damages resulting from non-conformity of the goods will be limited to proven damage (including consequential damage, loss of profit, etc.) up to the maximum limit of the percentage specified below of the contract price.",
		"Price_percentage": "Price percentage",
		"Max_limit_percentage": "Percentage of the maximum limit",
		"Seller_percentage": "Seller percentage"
	},
	"A11": {
		"TITLE": "A-11 Limitation of liability for non-compliance (Article 11)",
		"ALERT_MSG": "TO BE COMPLETED ONLY IF THE PARTIES WISH TO AMEND ART. 11.5",
		"PRICE_REDUCTION_PERCENTAGE_CTA": "The price reduction for accepting non-compliant products cannot exceed the percentage specified below of the price of such products.",
		"PRICE_REDUCTION_VALUE_CTA": "The price reduction for accepting non-compliant products cannot exceed the amount specified below.",
		"Percentage": "Percentage",
		"Amount": "Amount"
	},
	"A12": {
		"TITLE": "A-12 Limitation of liability in case the buyer decides to keep non-compliant products (Art. 11.6)",
		"ALERT_MSG": "TO BE COMPLETED ONLY IF THE PARTIES WISH TO AMEND ART. 11.6",
		"EXCEED_PERCENTAGE_CTA": "The price reduction for accepting non-compliant products will not exceed the (specified) percentage of the price of such products.",
		"EXCEED_AMOUNT_CTA": "The price reduction for accepting non-compliant products will not exceed the (specified) amount",
		"Percentage": "Percentage",
		"Amount": "Amount"
	},
		"A13": {
		"TITLE": "A-13 Limitation period (Art. 11.8)",
		"ALERT_MSG": "TO BE COMPLETED ONLY IF THE PARTIES WISH TO AMEND ART. 11.8",
		"DAYS_MSG": "Any action for non-conformity of the products (as defined in article 11.8) must be brought by the Seller within the days from the date of arrival of the products at destination specified below.",
		"Days": "Days"
	},
	"A14": {
		"TITLE": "A-14 Applicable law (Art. 1.2)",
		"ALERT_MSG": "To be completed only if the parties wish to subject the sales contract to a national law instead of the CVIM. The solution indicated below is not recommended (see Introduction, § 3).",
		"LAW_MSG": "This sales contract is subject to the following internal law.",
		"LAW2_MSG": "Any matter not covered by the CVIM shall be governed by the following law.",
		"Law": "Law"
	},
	"A15": {
		"TITLE": "A-15 Dispute Resolution (Art. 14)",
		"ALERT_MSG": "The two solutions indicated below (arbitration or ordinary courts) are alternatives: the parties cannot choose both. In the absence of choice, ICC arbitration will apply, in accordance with Article 14.",
		"Dispute_resolution": "Dispute Resolution",
		"OPTION_0": "ADR - Alternative Dispute Resolution",
		"OPTION_1": "ARBITRATION",
		"OPTION_2": "CCI (in accordance with art. 14.1)",
		"OPTION_3": "Other (specify)",
		"OPTION_4": "Ordinary jurisdiction (specify)"
	},
		"A16": {
		"TITLE": "A-16 Other"
	},
	"accountPage": {
		"My_account": "My account",
		"Anonymous_identity": "Anonymous identity",
		"My_contracts": "My contracts",
		"Contract_model": "Contract model",
		"My_role": "My role",
		"Seller": "Seller",
		"Buyer": "Buyer",
		"Contract_name": "Contract name",
		"Create_contract": "Create contract"
	},
	"viewContractPage": {
		"View_contract": "View contract",
		"Contract_draft": "Contract draft",
		"CONTRACT_DRAFT_MSG": "This contract is a draft. Store it on the blockchain to historicize it in a persistent and secure manner via the blockchain system.",
		"Store_contract": "Store contract",
		"Contract_saved": "Contract saved",
		"CONTRACT_SAVED_MSG": "This contract has been historicized in a persistent and secure manner via the blockchain system.",
		"Sign_contract": "Sign contract",
		"Contract_signed": "Contract signed",
		"CONTRACT_SIGNED_MSG": "This contract has been historicized and signed in a persistent and secure manner via the blockchain system. The goods are ready to be shipped.",
		"Contract_status": "Stato del contratto",
		"Saved": "Saved",
		"Signed": "Signed",
		"Shipped": "Shipped",
		"Delivered": "Delivered",
		"Completed": "Completed",
		"Contract_details": "Contract details",
		"Contract_id": "Contract ID",
		"Contract_name": "Contract name",
		"Contract_model": "Contract model",
		"Seller": "Seller",
		"Buyer": "Buyer",
		"Seller_signature": "Seller signature",
		"Buyer_signature": "Buyer signature",
		"Open_PDF": "Open PDF"
	},
	"shareContractPage": {
		"Share_your_contract": "Share your contract",
		"SHARE_MSG": "Write a message and share your contract",
		"SHARE_TEXTAREA_MSG": "Hi! Here the contract I initialized, click and add it to your TradeOnChain account and it is ready to be signed!",
		"Sign_contract": "Sign contract",
		"SHARE_CTA": "Share",
		"URL_MSG": "Contract shareable link",
		"URL_CTA": "Copy link",
		"QRCODE_MSG": "Contract shareable QR Code"
	},
	"addContractPage": {
		"Add_contract": "Add contract",
		"ADD_CONTRACT_MSG": "Click on the 'Add' button to add this contract to your account and collaborate on the drafting."
	},
	"languages": {
		"Italian": "Italian",
		"English": "English"
	}
}